<template>
  <Draggable
    v-model="items"
    handle=".drag-handle"
    :group="{ name: 'menu-items' }"
    draggable=".menu-item"
    @change="$emit('dragEvent', $event)"
    @start="setDraggingState(true)"
    @end="setDraggingState(false)"
  >
    <transition-group name="list">
      <template v-for="(id, i) of items" name="list" mode="out-in">
        <WithItem :id="id" v-slot="{ theItem }" :key="id">
          <BaseMenuComponent
            :ref="`item-${i}`"
            name="MenuItem"
            :class="itemClass"
            :entity="{
              ...theItem,
              ...{ isLast: i === items.length - 1 },
            }"
            :updated-at="section.updated_at"
            :is-condensed="section.section_type === 'condensed'"
            :section-type="section.section_type"
            @addItemBefore="$emit('addItem', i)"
            @addItemAfter="$emit('addItem', i + 1)"
            @deleteItem="$emit('deleteSectionItem', { id })"
            v-on="$listeners"
          />
        </WithItem>
      </template>
    </transition-group>
  </Draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import arrayMove from 'array-move'
import { mapActions } from 'vuex'

export default {
  name: 'MenuSectionItems',
  components: { Draggable },
  props: {
    section: {
      type: Object,
      required: true,
      default: () => {},
    },
    itemIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    items: {
      get() {
        return this.itemIds
      },
      set() {
        // nothing
      },
    },
  },
  methods: {
    ...mapActions('editor', ['setDraggingState']),
    async dragEventAction(evt) {
      let newItemSet = this.items ? [...this.items] : []
      let sectionId = this.entity.id
      if (evt.moved) {
        let newOrder = arrayMove(
          newItemSet,
          evt.moved.oldIndex,
          evt.moved.newIndex
        )

        await this.updateSectionItems({ items: newOrder, sectionId })
      }
      if (evt.added) {
        let itemId = evt.added.element
        newItemSet.splice(evt.added.newIndex, 0, itemId)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
      if (evt.removed) {
        newItemSet.splice(evt.removed.oldIndex, 1)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
    }, // dragEventAction
  },
}
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateX(-20%);
}
</style>
